<template>
  <div>
    <div class="intro">
      <div class="intro-text">
        <h1 class="intro-title">404</h1>
        <div class="line-spacer"></div>
        <p><span>Page not found</span></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "404",

  title() {
    return `${this.title} - 404`;
  },

  data() {
    return {
      title: this.$store.state.title,
    };
  },
};
</script>
